// ScrollMemory.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { LRUCache } from 'lru-cache';
import { matchPath } from 'react-router-dom';

import withRouter from '../component/WithRouter.jsx';

import { getIsInBrowserMainThread } from '../resource/getJsEnvironment.js';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../resource/defaults.js';

const isClient = getIsInBrowserMainThread();
const cache = new LRUCache({ max: 256 });

export class ScrollMemory extends React.Component {
  scrollTo = scrollNumber => {
    window.setTimeout(() => {
      window.scrollTo({ top: scrollNumber || 0, behavior: 'auto' });
    }, 100);
  };

  getScrollPage = () => {
    const docScrollTop = document?.documentElement?.scrollTop || 0;
    return window.scrollY || docScrollTop;
  };

  getNeedScroll = pathname => {
    const { disabledList } = this.props;
    const isDisabled = disabledList.find(pattern =>
      matchPath({ path: pattern, end: true }, pathname)
    );
    return !isDisabled;
  };

  locationChangedHandler = (actual, next) => {
    // get scroll of the page or the element before change location
    const scroll = this.getScrollPage();

    const shouldScroll = this.getNeedScroll(next.pathname);
    const shouldMemory = this.getNeedScroll(actual.pathname);

    if (shouldScroll) {
      // scroll when locationChanged
      this.scrollTo(cache.get(next.pathname) || 0);
    }

    // save scroll with pathname
    if (shouldMemory) {
      cache.set(actual.pathname, scroll);
    }
  };

  shouldComponentUpdate(nextProps) {
    if (!isClient) return false;
    const { location } = this.props;
    // location before change url
    const actual = location;
    // location after change url
    const next = nextProps.location;

    // if hash => let the normal operation of the browser
    const locationChanged =
      (next.pathname !== actual.pathname || next.search !== actual.search) &&
      next.hash === '';

    if (locationChanged) {
      this.locationChangedHandler(actual, next);
    }
    // never render
    return false;
  }

  detectPop = () => {
    if (!isClient) return;
    const { location } = this.props;
    // get the next for scroll position
    const nextFind = cache.get(location.pathname) || 0;

    this.getNeedScroll(location.pathname) && this.scrollTo(nextFind);
  };

  componentDidMount() {
    if ('scrollRestoration' in window.history) {
      // refer to https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration
      // auto: The location on the page to which the user has scrolled will be restored.
      // manual: The location on the page is not restored. The user will have to scroll to the location manually.
      window.history.scrollRestoration = 'manual';
    }
    window.addEventListener('popstate', this.detectPop);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectPop);
  }

  render() {
    return null;
  }
}

ScrollMemory.propTypes = {
  location: PropTypes.object,
  /**
   * A list of URL patterns that should disable scroll memory.
   *
   * @type {string[]}
   * @example
   * ['/u/:username', '/user/:id']
   */
  disabledList: PropTypes.array,
};

ScrollMemory.defaultProps = {
  location: EMPTY_OBJECT,
  disabledList: EMPTY_ARRAY,
};

export default withRouter(ScrollMemory);
